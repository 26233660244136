<template lang="html">
<div class="active-invite" v-if="show">
  <a-row class="with-margin">
      <a-col :span="24">
        <div style="text-align:center">
          <svg width="200px"  height="200px"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="lds-eclipse" style="background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;">
            <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#1d3f72">
              <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
            </path>
          </svg>
        </div>
      </a-col>
    </a-row>
</div>
</template>

<script>
export default {
  name: "loader",
  props:{
    show: {
      type: Boolean,
      default: false
    }
  }
}
</script>