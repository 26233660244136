import Vue from 'vue'
import VueRouter from 'vue-router'
import SigninAs from '../templates/signin_as.vue'
import I18n from "./i18n";

Vue.use(VueRouter)

function loadView(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `../templates/${view}.vue`)
}

// 2. Define some routes
// Each route should map to a component. The "component" can
// either be an actual component constructor created via
// `Vue.extend()`, or just a component options object.
// We'll talk about nested routes later.
const routes = [
    { path: '/', redirect: '/organizations' },
    { path: '/organizations',     component: loadView('organizations_list'), name: 'org-list'},
    { path: '/organizations/:id', component: loadView('organization_campaigns'), name: "org-campaigns"},
    { path: "/organizations/:id/:camp_id", components: {default: loadView('organization_content')},
        meta: {sidebar: true},
        children: [
            {path: "settings",  component: loadView('organization_camp_settings'),  name: "org-settings"},
            {path: "customize", component: loadView('organization_camp_customize'), name: "org-customize"},
            {path: "preview",   component: loadView('organization_camp_preview'),   name: "org-preview"},
            {path: "results",   component: loadView('organization_camp_results'),   name: "org-invites"},
            {path: "matching_incompleted", component: loadView('organization_camp_matching_incompleted'),   name: "org-matching-incompleted"}
        ]
    },
    { path: "/family", component: loadView('family_content'),
        children: [
            {path: "invites/:token/:lang?", component: loadView('family_invite'), name: 'family-invite'}
        ]
    },
    { path: "/enterprise/accounts",     component: loadView('enterprise/accounts_list')},
    { path: "/enterprise/accounts/:id", component: loadView('enterprise/account_campaigns')},
    { path: "/enterprise/accounts/:id/:camp_id", components: {default: loadView('enterprise/account_content')},
        meta: {sidebar: true},
        children: [
            {path: "settings",  component: loadView('enterprise/account_camp_settings'),  name: "account-settings"},
            // {path: "customize", component: loadView('organization_camp_customize'), name: "org-customize"},
            // {path: "preview",   component: loadView('organization_camp_preview'),   name: "org-preview"},
            // {path: "results",   component: loadView('organization_camp_results'),   name: "org-invites"},
            // {path: "matching_incompleted", component: loadView('organization_camp_matching_incompleted'),   name: "org-matching-incompleted"}
        ]
    },
    { path: '/signin_as', component: SigninAs, name: 'signin_as'},
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
export const router = new VueRouter({
    mode: 'history',
    routes, // short for `routes: routes`
    scrollBehavior (to, from, savedPosition) {
      // return desired position
      if (savedPosition == null){
          window.scrollTo(0, 0);
      }
    },
})

router.beforeEach((to, from, next)=>{
    if (to.params.lang && ['en', 'es', 'it', 'pt', 'ru', 'vi', 'so', 'zh'].includes(to.params.lang)) { I18n.locale = to.params.lang }
    next()
});
router.afterEach((to, from) => {
    gtag('config', 'UA-16619512-1', {'page_path': to.path});
    gtag('config', 'UA-16619512-1', {
        'linker': {
            'domains': ['students.arbitersports.com', 'account.students.arbitersports.com', 'hello.students.arbitersports.com', 'invitations.students.arbitersports.com']
        }
    });
});
