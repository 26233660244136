<template lang="html">
  <div>Loading....</div>
</template>

<script>
export default {
  created (){
    this.$http.post("api/auth/signin_as", this.$route.query).then((resp)=>{
      this.$router.push({name: "org-list"})
      console.log(resp)
    }).catch((resp)=>{
      console.log("catch error:", resp)
    })
  }
}
</script>