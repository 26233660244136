import MomentTZ from 'moment-timezone'
import I18n from './i18n'

export default {
  data (){
    return {
      reg_states:{
        draft: 'Draft',
        pending: 'Not Submitted',
        expired: 'Not Submitted',
        canceled: 'Canceled',
        refunded: 'Refunded',
        completed: 'Completed',
        confirmed: 'Completed',
        correction_required: 'Correction Required',
        deposit_paid: 'Deposit paid',
        partial: 'Deposit paid',
        partial_refunded: 'Partial refund',
        paid: 'Paid',
        listed_canceled: 'Canceled',
        none: "None",
        void: "None",
      }
    }
  },
  methods: {
    oname (obj){
      if (!obj) return ""
      return `${obj.first_name} ${obj.last_name}`
    },
    name (obj){
      return this.oname(obj)
    },
    lname (obj){
      if (!obj) return ""
      return `${obj.last_name}, ${obj.first_name}`
    },
    replaceTplVars (tpl){
      if (!tpl) return;
      let match = {participant: this.nullParticipant()}
      return tpl.replace(/\[Participant Name\]/g, this.name(match.participant))
    },
    nullParticipant (){
      return this.participant || {first_name: "[Participant",last_name: "Name]"}
    },
    strToDate (str){
        return `${MomentTZ(str).tz('America/New_York').format('L LT z')}`
    },
    unixToDate (unix){
        return `${MomentTZ.unix(unix).tz('America/New_York').format('L LT z')}`
    },
    t(str, opts = {}){
        this.$root.$children[0].translations //hack to make lazy load reactive
        return I18n.t(str, opts)
    },
    l(str, opts = {}){
        this.$root.$children[0].translations //hack to make lazy load reactive
        return I18n.toTime(opts.format, str)
    },
  },
  computed: {
    program_closed (){
      if (!this.campaign)                      return false
      if (!this.campaign.fid_program_cache)    return false
      if (!this.campaign.fid_program_cache.id) return false
      return this.campaign.fid_program_cache.render_registration_state==='closed'
    },
    
    route (){ return this.$route.params },
    state (){ return this.$store.state },
  }
}