/* eslint no-console:0 */

import 'core-js/stable'
import 'regenerator-runtime/runtime'


import "styles/antd.less";
import "styles/main.scss";

Array.prototype.last = function() {
  return this[this.length - 1];
};
Array.prototype.find_by_id = function(id) {
  var el;
  if (id == null) return null;
  else if(id === -1) return {};

  for (var i = 0; i < this.length; i++) {
    el = this[i];
    if (el.id === parseInt(id)) return el;
  }
  return null;
};

import Vue from 'vue';
import VueResource from 'vue-resource';
import {store} from "code/store.js";
import {router} from "code/routes.js";

// Doc: https://vuecomponent.github.io/ant-design-vue/components/tooltip/
// Doc: https://github.com/vueComponent/ant-design-vue/blob/master/components/index.js
import { Layout } from 'ant-design-vue';
import { Card } from 'ant-design-vue';
import { Icon } from 'ant-design-vue';
import { Row } from 'ant-design-vue';
import { Col } from 'ant-design-vue';
import { Button } from 'ant-design-vue';
import { Dropdown } from 'ant-design-vue';
import { Spin } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import { Table } from 'ant-design-vue';
import { Form } from 'ant-design-vue';
import { FormModel } from 'ant-design-vue';
import { Input } from 'ant-design-vue';
import { InputNumber } from 'ant-design-vue';
import { Menu } from 'ant-design-vue';
import { Breadcrumb } from 'ant-design-vue';
import { Divider } from 'ant-design-vue';
import { Select } from 'ant-design-vue';
import { Checkbox } from 'ant-design-vue';
import { Radio } from 'ant-design-vue';
import { message } from 'ant-design-vue';
import { notification } from 'ant-design-vue';
import { Tabs } from 'ant-design-vue';
import { AutoComplete } from 'ant-design-vue';
import { Tag } from 'ant-design-vue';
import { Tooltip } from 'ant-design-vue';

import App from 'app.vue';

Vue.use(Layout);
Vue.use(Card);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Button);
Vue.use(Dropdown);
Vue.use(Spin);
Vue.use(Modal);
Vue.use(Table);
Vue.use(Form);
Vue.use(FormModel);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Menu);
Vue.use(Breadcrumb);
Vue.use(Divider);
Vue.use(Select);
Vue.use(Checkbox);
Vue.use(Radio);
Vue.use(Tabs);
Vue.use(AutoComplete);
Vue.use(Tag);
Vue.use(Tooltip);

Vue.use(VueResource);

window.csrf = ()=>{
  return document.querySelector("meta[name='csrf-token']")?.content
}
Vue.http.headers.common['X-CSRF-Token'] = window.csrf()

Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
Vue.prototype.$info = Modal.info;
Vue.prototype.$success = Modal.success;
Vue.prototype.$error = Modal.error;
Vue.prototype.$warning = Modal.warning;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$destroyAll = Modal.destroyAll;

document.addEventListener('DOMContentLoaded', () => {
    const props = JSON.parse(document.body.getAttribute('data'));
    Vue.prototype.$fid = {};
    for (let k of Object.keys(props)) {
        Vue.prototype.$fid[k] = props[k];
    }

    const app = new Vue({
        store,  // means `store: store`
        router,
        render: h => h(App)
    }).$mount('#main-app');
});
