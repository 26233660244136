<template lang="html">
  <a-layout-header class="second-header">
    <nav class="main-navigation">

      <div class="wrapper" v-if="state.current_user.organizations.length>0 && isOrgPath()">
        <ul v-if="menu.length === 0" class="horizontal-nav">
          <li>
            <a :href="$fid.weburl + '/account'">Organization</a>
          </li>
          <li>
            <a class="active">Invitations</a>
          </li>
        </ul>
        <ul v-else class="horizontal-nav">
          <template v-for="link in menu">
            <li v-if="link.children">
              <a-dropdown >
                <a :href="link.path">{{link.name}}</a>
                <a-menu slot="overlay">
                  <a-menu-item v-for="sublink in link.children" :key="sublink.name">
                    <a v-if="sublink.path" :href="$fid.weburl+sublink.path">{{sublink.name}}</a>
                    <p v-else v-html="sublink.name" />
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </li>
            <li v-else-if="link.name">
              <a v-if="link.type==='invitations'" class="active">Invitations</a>
              <a v-else :href="$fid.weburl+link.path">{{link.name}}</a>
            </li>
            <template v-else-if="link.type === 'locale_switch'">
              shared/switch_language
            </template>
          </template>
        </ul>
        <div class="choose-profile">
          <a-dropdown :trigger="['click']">
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              <span class="selected">{{ state.current_org.name || "Choose organization"}}</span>
              <a-icon type="down"/>
            </a>
            <a-menu slot="overlay">
              <a-menu-item v-for="org in state.current_user.organizations" :key="'org-'+org.id" @click="changeOrg(org)">
                {{org.name}}
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </div>

      <div class="wrapper" v-else>
        <ul class="horizontal-nav">
          <li>
            <a :href="$fid.weburl + '/family/dashboard'">Dashboard</a>
          </li>
          <li>
            <a class="active">Invitations</a>
          </li>
        </ul>
        <span class="switch-language" v-if="this.$route.name === 'family-invite'">
          <a-select style="width: 100px" @change="setLocale"  :default-value="this.I18n.locale" dropdownClassName="language-dropdown">
            <a-select-option :value="lang[0]" :key="lang[0]" v-for="lang in locales">{{lang[1]}}</a-select-option>
          </a-select>
        </span>
      </div>
    </nav>

  </a-layout-header>
</template>
<script>
import I18n from "../../code/i18n";

const locales = [['en', 'English'], ['zh', 'Chinese'], ['it', 'Italian'],['pt', 'Portuguese'],['ru', 'Russian'], ['so', 'Somali'], ['es', 'Spanish'], ['vi','Vietnamese']]
export default {
  data: ()=>({
    locales,
    menu: [],
  }),
  watch: {
    "I18n.locale"(){ this.loadMenu() },
    "state.current_org.id"(){ this.loadMenu() }
  },
  created(){
    if (this.state.current_org.id) this.loadMenu()
  },
  methods: {
    isOrgPath(){
      return this.$route.path.startsWith("/org")
    },
    loadMenu(){
      this.$http.get(`/api/config/topmenu?organization_id=${this.org_id}&locale=${I18n.locale}`).then((resp)=>{
        this.menu = resp.data
      })
    },
    changeOrg(org){
      this.$router.push(`/organizations/${org.id}`, {query: {ux: new Date().getTime()}})
    }
  },
  computed: {
    state() {
      return this.$store.state
    },
    org_id(){
      return window.location.pathname.split("/")[2]
    },
  },
}
</script>
