<template lang="html">
  <div id="app">
    <Loader :show="checking_user" />
    <a-layout v-if="!checking_user">
      <a-layout-header v-if="$route.fullPath === '/organizations'" class="no-border-bottom">
          <div class="logo">
            <a :href="$fid.weburl">
              <img src='https://email-assets.familyid.com/arbiter-logo.svg'/>
            </a>
          </div>
      </a-layout-header>
      <template v-else>
        <a-layout-header  class="first-header">
          <div class="wrapper">
            <div class="left-part">
              <div class="logo-alt">
                <a :href="$fid.weburl">
                  <div class="arbiter-logo"></div>
                </a>
              </div>
              <section class="contact-us">
                <a href="https://arbitersportshelp.zendesk.com/hc/en-us" class="contact-us-link" target="_blank">Technical Support</a>
                <span class="icon-email"></span>
                <a :href="`mailto:${this.mail_to}`" class="contact-us-mail">support@arbitersports.com</a>
                <span class="icon-phone"></span>
                <span class="contact-us-phone">1-800-311-4060</span>
              </section>
            </div>
            <div class="account">
              <span class="icon-user"></span>
              <a-dropdown>
                <a class="ant-dropdown-link">
                  {{state.current_user.first_name + ' ' + state.current_user.last_name}}
                  <a-icon type="down"/>
                </a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a :href="$fid.weburl + '/profile'">Profile</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a @click="logOut">Sign out</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>


          </div>
        </a-layout-header>
        <second_header @loadOrg="loadOrg" />
      </template>

      <a-layout-content>
            <a-row class="with-margin" style="padding-bottom: 2rem;">
              <a-col :span="24" v-if="hasSidebar">
                <a-breadcrumb style="float:left" v-if="hasBreadcrumb">
                  <a-breadcrumb-item><router-link :to="'/organizations/'+state.current_org.id">Invitations</router-link></a-breadcrumb-item>
                  <a-breadcrumb-item>{{state.campaign.name || 'Campaign'}}</a-breadcrumb-item>
                </a-breadcrumb>
                <router-link :to="`/organizations/${$route.params.id}`" style="float:right;">
                  <a><strong>View all invitations</strong></a>
                </router-link>
              </a-col>
            </a-row>
            <router-view/>
      </a-layout-content>

      <a-layout-footer :class="header_class">
        <div class="main-footer">
          <div class="row footer-layout">
            <div class="footer-navigation">
              <ul class="horizontal-nav">
                <li>
                  <a :href="hello_page_url('how-it-works')">How it Works</a>
                  <ul class="vertical-nav">
                    <li><a :href="hello_page_url('for-schools')">For Schools</a></li>
                    <li><a :href="hello_page_url('for-community-organizations')">For Community Programs</a></li>
                    <li><a :href="hello_page_url('for-families')">For Families</a></li>
                    <li><a :href="hello_page_url('data-security')">Data Security</a></li>
                  </ul>
                </li>
                <li>
                  <a :href="hello_page_url('about-us')">About Us</a>
                  <ul class="vertical-nav">
                    <li><a :href="hello_page_url('who-we-serve')">Who We Serve</a></li>
                    <li><a :href="hello_page_url('resource-center')">Resource Center</a></li>
                    <li><a :href="hello_page_url('about-us/careers')">Careers</a></li>
                  </ul>
                </li>
                <li>
                  <a :href="hello_page_url('get-in-touch')">Resources</a>
                  <ul class="vertical-nav">
                    <li><a href="https://arbitersportshelp.zendesk.com/hc/en-us" target="_blank">Help</a></li>
                    <li><a :href="`${$fid.weburl}/organizations/search`">Find Programs</a></li>
                    <li><a :href="hello_page_url('get-in-touch')">Get in Touch</a></li>
                    <li><a href="https://portal.productboard.com/arbiter/2-arbiter-product-portal/tabs/5-planned">Feedback Portal</a></li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="logged-in">
              <section class="contact">
                <section class="contact-us">
                  <span class="contact-us-text">
                    <h4>Technical Support</h4>
                  </span>
                  <span class="icon-email-white"></span>
                  <a :href="`mailto:${this.mail_to}`" class="contact-us-link">support@arbitersports.com</a>
                  <span class="icon-phone-white"></span>
                  <span class="contact-us-phone">800-311-4060</span>
                </section>
                <section class="social-media">
                  <span class="follow-us-text">
                    <h4>Follow Us</h4>
                    <a href="https://www.instagram.com/ArbiterSports/" target="_blank">
                      <i class="fab fa-instagram"/>
                    </a>
                    <a href="https://twitter.com/ArbiterSports" target="_blank">
                      <i class="fab fa-twitter"/>
                    </a>
                    <a href="https://www.facebook.com/ArbiterSports" target="_blank">
                      <i class="fab fa-facebook"/>
                    </a>
                    <a href="https://www.linkedin.com/company/arbiter-sports" target="_blank">
                      <i class="fab fa-linkedin-in"/>
                    </a>
                  </span>
                </section>
              </section>
            </div>
          </div>
          <br>
          <div class="copyright">
            <div class="copyright-text">
              <div class="left">
                © {{new Date().getFullYear()}} ArbiterSports. All rights reserved
              </div>
              <div class="right">
                <a :href="hello_page_url('privacy-policy')">Privacy Policy</a>
                |
                <a href="https://www.arbitersports.com/terms-conditions" target="_blank">Terms of Service</a>
              </div>
            </div>
          </div>
        </div>
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
import Loader from './templates/_loader'
import Vue from 'vue'
import second_header from './templates/shared/_second_header'

import I18n from './code/i18n'
import helpers from 'code/mixins'
export default {
        components: {Loader, second_header},
        name: 'app',
        mixins: [helpers],
        data: function () {
            return {
              checking_user: true,
              date: null,
              auth: null,
              I18n: I18n,
              mail_to: process.env.RAILS_ENV === "production" ? "support@arbitersports.com" : "noreply@arbitersports.com"
            }
        },
        created() {
            window.app=this
            this.$store.state.auth_state = this.loadAuthentication().then(this.loadOrg)

            // for translations
            window.I18n = I18n
            this.loadTranslations()

            //this.$http.get('/users/current').then(resp=>this.$store.state.current_user = resp.data)
            // end for translation
        },
        watch: {
            '$route'() {this.loadOrg()}
        },
        methods: {
            loadTranslations(){
                this.$http.get('/api/config/i18n').then((response)=> {
                    this.translations=I18n.translations = response.data
                    window.localStorage.setItem("fid_i18n", JSON.stringify(response.data))
                })

                if (window.localStorage.getItem("fid_i18n")){
                    this.translations=I18n.translations = JSON.parse(window.localStorage.getItem("fid_i18n"))
                }
            },
            setLocale(locale){
                I18n.locale = locale
            },
            loadOrg(){
              if ( this.state.current_user.id && this.isOrgPath() ){
                const org = this.state.current_user.organizations.find(e => e.id === parseInt(this.$route.params.id)) || {}
                this.$store.commit('setOrg', org)
              }else
                  this.$store.commit('setOrg', {})
            },
            isOrgPath(){
                return this.$route.path.startsWith("/org")
            },
            loadAuthentication() {
                if(this.$route.name === "signin_as"){
                  this.checking_user = false
                  return
                }

                if (window.location.search.indexOf('auth=')>-1) {
                    return this.$http.get('/api/login'+window.location.search).then(this.checkUserCallback)
                } else {
                    return this.checkUser()
                }
            },
            checkUser(){
              return this.$http.get('/api/user').then(this.checkUserCallback)
            },
            checkUserCallback(res){
              const json = res.body
              if (json.object && json.object.id) {
                this.setUser(json)
                this.checking_user=false
                // if org, but from previous one, redirect to choose org
                if (this.isOrgPath() && this.$route.params && this.$route.params.id){
                  const org_ids = json.object.all_organization_ids
                  if (!org_ids.includes(parseInt(this.$route.params.id))){
                    this.$notification.open({
                      message: "Organization is missing",
                      description: "You don't have an access to this organization. Please choose one"
                    })
                    return window.location = '/organizations'
                  }
                }
                if (res.url.includes("auth=")){
                  this.$router.push(window.location.pathname)
                  return this.checkUser()
                }
              } else {
                this.$store.commit('logout');
                this.$http.get('/api/prelogin', {params: {path: window.location.href}}).then(this.successCallback, this.errorCallback);
              }
            },

            setUser (json){
                this.$store.state.current_user = json.object
                this.$store.state.current_user.organizations = this.$store.state.current_user.organizations.sort((a,b)=> a.name.localeCompare(b.name))
                Vue.http.headers.common['Access-Token']      = json.object.current_token
            },
            logOut(){
              this.$http.get('/api/logout').then((res)=>{
                this.$store.commit('logout')
                window.location = this.$fid.weburl
              },this.errorCallback)
            },
            successCallback(res) {
                if (res.body.completed) {
                    window.location = res.body.redirect
                } else {
                    alert("Couldn't connect to serer")
                }
            },
            errorCallback() {
                alert("Unknown error happened")
            },
          hello_page_url(path) {
            let url = "https://hello.students.arbitersports.com/"
            if (["who-we-serve", "resource-center", "about-us/careers"].includes(path)) { url= "https://www.arbitersports.com/" }
            url += path
            if(this.$fid.env !== 'production'){url += `?from=${this.$fid.env}`}
            return url
          }
        },
        computed: {
            state() {
                return this.$store.state
            },
            header_class() {
              const name = (this.$route.matched[0] || {}).name
              return `header ${name}`
            },
            hasSidebar(){
              for (const m of this.$route.matched){ if (m.meta.sidebar) return true;}
              return false;
            },
            hasBreadcrumb(){
                return !this.$route.path.endsWith("results")
            }
        }
}
</script>

<style lang='scss'>
@import './styles/variables';
.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  max-width: 107rem;
  margin: auto;
}

.no-border-bottom {
  border: 0; padding-bottom: 1.3rem;
}

.logo {
  display: block;
  margin-top: 3rem;
  text-align: center;

  img {
    max-width: 15rem;
  }
}

.logo-alt {
  img {
    max-width: 12rem;
    max-height: 5rem;
    height: 5.5rem;
  }
}

.language-dropdown {
  .ant-select-dropdown-menu{
    max-height: 25rem !important;
  }
}
</style>
