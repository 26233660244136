import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//https://www.youtube.com/watch?v=iw1eajzWQAM
// using ...
// check mapGetters
//to watch https://www.youtube.com/watch?v=dkFWOsKrPAI

export const store = new Vuex.Store({
    state: { // keyword to set variables
        current_user: {organizations: []},
        current_org: { id: null, },
        campaign: {
          name: "",
          fid_program_id: null,
          fid_program_cache: {},
          require_program: false,
        },
        cached_campaigns: {},
        auth_state: null,
        modals: {
          invitation_sent: {}
        },
    },
    getters: {
        campaign: (state) => (that) => { // state comes automatically
            const id = that.$route.params.camp_id
            if (!id) return Promise.resolve({})
            // not caching for now
            // if (state.cached_campaigns[id]) return Promise.resolve(state.cached_campaigns[id])
            const params = that.def_params ? that.def_params : {organization_id: that.$route.params.id}

            return that.$http.get(`/api/invite_campaigns/${id}`, {params: params}).then((res)=> {
                state.cached_campaigns[id] = res.body.object
                state.campaign = res.body.object
                return state.cached_campaigns[id]
            })
        },
    },
    mutations: {
        logout (state) {
            // mutate state
            state.current_user = {organizations: []}
            window.localStorage.removeItem('st-import-current_user')
        },
        calculated_logout (state, res) {
            // mutate state
            if (res.body.errors && res.body.errors.base && res.body.errors.base.includes("no_access_to_object")){
                console.log("session expired")
                res.that.$message.error('you need to re-login in Arbiter Registration', 5000)
                this._mutations.logout[0]()
            }
            if (res.body.object.errors && res.body.object.errors.base && res.body.object.errors.base.includes("no_access_to_object")){
                console.log("session expired")
                res.that.$message.error('you need to re-login in Arbiter Registration', 5000)
                this._mutations.logout[0]()
            }

        },
        setOrg (state, org){
            state.current_org = org
        }
    }
})
